.App {
  height: 100vh;
  padding: 72px 112px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  font-size: 22px;
  text-align: center;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 35px;
}

main {
  padding: 2em;
  border-bottom: 1px solid black;
}

footer {
  padding: 2em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}